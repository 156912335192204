import React from "react";
import styles from "./styles.module.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import { IoOpenOutline } from "react-icons/io5";


const Footer = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.top}>
        <div className={styles.contact}>
          <h2>Contact Us</h2>
          <p>
            <strong>Address:</strong> &nbsp; BITS Pilani Hyderabad Campus
          </p>
          <p>
            <strong>Email:</strong>&nbsp; teamagrivolt@gmail.com
          </p>
          <p>
            <strong>Phone:</strong>&nbsp; +91 7045669957
          </p>
        </div>
        <div className={styles.form}>
          <h2>Subscribe to our newsletter</h2>
          <form>
            <input type="email" placeholder="Email" />
            <button type="button">Subscribe</button>
          </form>

          <div className={styles.redirects}>
            <div>
              <a href="https://forms.gle/ktzMquKV3S9PFtGv7" target="_blank">
                Survey form for farmers &nbsp;
                <IoOpenOutline />
              </a>
            </div>
            <div>
              <a href="https://forms.gle/oAYAGMMZem2aeZjc7" target="_blank">
                Survery form for factory owners/institutions &nbsp;
                <IoOpenOutline />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.socials}>
          <a
            href="https://www.facebook.com/agrivolt"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/agrivolt"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/agrivolt-agrivolatics/about/?viewAsMember=true"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoLinkedin />
          </a>
          <a href="mailto:teamagrivolt@gmail.com" target="_blank" rel="noreferrer">
            <MdOutlineEmail />
          </a>
        </div>
        <p>© 2024 copyright of Agrivolt Pvt Ltd.</p>
      </div>
    </footer>
  );
};
export default Footer;
