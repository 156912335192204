import styles from "./styles.module.css";
import Positive from "../components/Positive/Positive";
import AboutUsImg from "../assets/aboutus.jpeg";
const AboutUs = () => {
  return (
    <Positive>
      <div className={styles.container}>
        <div className={styles.left}>
          <img src={AboutUsImg} alt="About Us" className={styles.img} />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>ABOUT US</div>
          <div className={styles.text}>
            At AgriVolt, we're not just another solar company. We're pioneering
            a revolution in India's journey to net-zero carbon emissions by
            integrating advanced agrivoltaic solutions. By empowering farmers
            and promoting sustainability, we aim to become the household name
            for agrivoltaics in India.
          </div>
          <div className={styles.title}>WHAT WE BELIEVE</div>
          <div className={styles.subtitle}>VISION</div>
          <div className={styles.text}>
            We at AgriVolt believe that we can empower farmers and help the
            environment by becoming the household name for agrivoltaics in
            India.
          </div>
          <div className={styles.subtitle}>MISSION</div>
          <div className={styles.text}>
            Our mission is to drive the adoption of agrivoltaics in India by
            offering a comprehensive platform and essential services. We aim to
            facilitate the transition to green energy for factory owners by
            serving as the primary point of contact with DISCOMs, farmers, and
            users, ensuring a seamless and effective integration of agrivoltaic
            solutions.
          </div>
        </div>
      </div>
    </Positive>
  );
};
export default AboutUs;

// About Us

// At AgriVolt, we're not just another solar company. We're pioneering a revolution in India's journey to net-zero carbon emissions by integrating advanced agrivoltaic solutions. By empowering farmers and promoting sustainability, we aim to become the household name for agrivoltaics in India.

// Vision

// We at AgriVolt believe that we can empower farmers and help the environment by becoming the household name for agrivoltaics in India.

//  Mission

// Our mission is to drive the adoption of agrivoltaics in India by offering a comprehensive platform and essential services. We aim to facilitate the transition to green energy for factory owners by serving as the primary point of contact with DISCOMs, farmers, and users, ensuring a seamless and effective integration of agrivoltaic solutions.
