import React from "react";
import "./HeroSection.css";
import video from "../../assets/video.mp4";
import Logo from "../../assets/logo.png";

function HeroSection() {
  

  return (
    <div className="hero-container">
      <div className="nav">
        <div className="left">
          <img src={Logo} className="logo" alt="logo" />
          AGRIVOLT
        </div>
        <div
          className="right"
        >
          <div className="item">About Us</div>
          <div className="item">Problem</div>
          <div className="item">Solution</div>
          <div className="item">Impact</div>
        </div>
        
      </div>

      <video src={video} autoPlay loop muted />
      <h1>AGRIVOLT</h1>
      <p>
        EMPOWERING FARMERS
        <br /> ENERGIZING INDIA
      </p>
      <div className="extra">
        A revolution in India’s road to net-zero carbon emissions
      </div>
    </div>
  );
}

export default HeroSection;
