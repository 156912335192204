import AboutUs from "../AboutUs";
import Problems from "../Problems";
import HeroSection from "../components/Hero/HeroSection";
import Negative from "../components/Negative/Negative";
import Positive from "../components/Positive/Positive";
import Team from "../Team";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <HeroSection />
      <AboutUs/>
      {/* <Problems /> */}
      <Team />
      <Footer />
    </>
  );
};

export default Home;
