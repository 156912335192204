import Negative from "../components/Negative/Negative";
import styles from "./styles.module.css";
import T1 from "../assets/T1.jpg";
import T2 from "../assets/T2.jpg";
import T3 from "../assets/T3.jpg";
import T4 from "../assets/T4.jpg";

const Team = () => {
  return (
    <Negative>
      <div className={styles.container}>
        <div className={styles.title}>OUR TEAM</div>
        <div className={styles.text}>
          We are a group of climate entrepreneurs passionate about solar energy
          and committed to driving change. Hailing from BITS Pilani, each of us
          has majored in Electrical and Electronics Engineering. Our shared
          enthusiasm for sustainability and renewable energy fuels our mission
          to integrate agrivoltaic solutions across India. we combine technical
          expertise with innovative thinking to empower farmers and promote a
          greener future.
        </div>
        <div className={styles.teams}>
          <div className={styles.team}>
            <img src={T1} alt="team" className={styles.img} />
            <div className={styles.name}>
              Vinay Chandra
              <div className={styles.position}>Co-Founder & CEO</div>
            </div>
          </div>
          <div className={styles.team}>
            <img src={T2} alt="team" className={styles.img} />
            <div className={styles.name}>
              Vaibhav Gupta
              <div className={styles.position}>Co-Founder & COO</div>
            </div>
          </div>
          <div className={styles.team}>
            <img src={T3} alt="team" className={styles.img} />
            <div className={styles.name}>
              Aditya Suresh
              <div className={styles.position}>Co-Founder & CBO</div>
            </div>
          </div>
          <div className={styles.team}>
            <img src={T4} alt="team" className={styles.img} />
            <div className={styles.name}>
              Aditya Shrivastava
              <div className={styles.position}>Co-Founder & CFO</div>
            </div>
          </div>
        </div>
        <div className={styles.text}>
          Together, we are AgriVolt, united by a shared vision to transform
          agriculture through innovation and sustainability.
        </div>
      </div>
    </Negative>
  );
};
export default Team;
